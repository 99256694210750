


<template>
    <div>
        <mdb-navbar v-show="navbarType == 'regular-fixed'" position="top"  dark color="indigo" name="Your Logo" href="#" scrolling>
            <a href="https://agustinostv-front.archive.tornadocore.es" style="color: white;padding-right: 30px"><b>AGUSTINOS.TV</b></a>

        </mdb-navbar>

        <div style=" margin-bottom: -25px;margin-top:-55px;">
            <div class="view intro-2">
                <div class="full-bg-img" style="height:30% !important;">
                    <div class="mask rgba-black-strong" style="padding-top: 24%;padding-bottom: 24%;padding-left: 60px;padding-right: 60px;">






                    </div>
                </div>
            </div>
        </div>
        <mdb-footer  dark color="indigo">
            <p class="footer-copyright mb-0 py-3 text-center">
                &copy; {{new Date().getFullYear()}} Copyright:
                <a
                        href="#"
                >AGUSTINOS.TV</a>
                | Versión 3.20.01.01
            </p>
        </mdb-footer>

        <mdb-modal :show="showModalRecuperar" @close="showModalRecuperar = false" cascade class="text-left">
            <mdb-modal-header class="primary-color white-text">
                <h4 class="title"><mdb-icon icon="pencil-alt" /> Recuperar Cuenta</h4>
            </mdb-modal-header>
            <mdb-modal-body class="grey-text">
                <p> Si tu cuenta ya fue activada en nuestro sistema puedes pedir un cambio de contraseña. (RECUPERAR) </p>
                <p>También puedes pedir que te reenviemos el código de activación si no llegaste a activarla. (Reenviar Activación) </p>
                <p>Introduce tu dirección de correo electrónico y espera las instrucciones </p>
                <mdb-input size="sm" label="Correo Electrónico" icon="envelope" v-model="email" group type="email" validate error="wrong" success="right"/>
            </mdb-modal-body>
            <mdb-modal-footer>
                <b-row  v-if="esta_en_vertical == false" style="width:100%">
                    <div class="col-md-6">
                        <a style="float:left;margin-top: 18px;" @click="PedirActivacion" href="https://agustinostv-front.archive.tornadocore.es/#/auth/recuperar-cuenta">Reenviar Activación</a>
                    </div>
                    <div class="col-md-6">
                        <mdb-btn color="primary" @click="Recuperar">Recuperar</mdb-btn>
                    </div>


                </b-row>

                <b-row v-if="esta_en_vertical == true" style="width:100%;text-align: center;align-content: center">
                    <div class="col-md-12">
                        <a style="margin-top: 18px;" @click="PedirActivacion" href="https://agustinostv-front.archive.tornadocore.es/#/auth/recuperar-cuenta">Reenviar Activación</a>
                    </div>
                    <div style="padding: 30px"></div>
                    <div class="col-md-12">
                        <mdb-btn color="primary" @click="Recuperar">Recuperar</mdb-btn>
                    </div>

                </b-row>
            </mdb-modal-footer>
        </mdb-modal>


    </div>
</template>






<script>

    import {
        mdbNavbar,
        mdbNavItem,
        mdbNavbarNav,
        mdbNavbarToggler,
        mdbNavbarBrand,
        mdbFooter,
        mdbTooltip,
        mdbDropdown,
        mdbDropdownItem,
        mdbDropdownMenu,
        mdbDropdownToggle,
        mdbInput,
        mdbContainer,
        mdbRow,
        mdbCol,
        mdbModal,
        mdbModalHeader,
        mdbModalBody,
        mdbModalFooter,
        mdbBtn,
        mdbIcon,
    } from "mdbvue"
    import ApiService from '@/services/api';

    export default {
        name: 'Login',
        components: {
            mdbNavbar,
            mdbNavItem,
            mdbNavbarNav,
            mdbNavbarToggler,
            mdbModal,
            mdbModalHeader,
            mdbModalBody,
            mdbModalFooter,
            mdbNavbarBrand,
            mdbFooter,
            mdbTooltip,
            mdbDropdown,
            mdbDropdownItem,
            mdbDropdownMenu,
            mdbDropdownToggle,
            mdbInput,
            mdbContainer,
            mdbRow,
            mdbCol,
            mdbBtn,
            mdbIcon,
        },
        data() {
            return {
                navbarType: 'regular-fixed',
                showModalRecuperar: true,
                esta_en_vertical:false,
                email: '',
            }
        },
        mounted() {
            if(window.innerHeight > window.innerWidth)
            {
                this.esta_en_vertical = true;
                document.querySelector("html").style.fontSize = '.80rem';
            }
        },
        created() {
            window.document.title = "AGUSTINOS.TV";
        },
        methods: {
            Recuperar(){

                console.log('haciendo login ...');
                ApiService.PedirRecuperarContrasenya(this.email).then(response => {
                    console.log(response);
                    if (response.status == 200) {
                        this.$swal.fire({
                            title: 'Petición enviada',
                            text: 'Revisa tu correo y sigue las instrucciones',
                            type: 'success',
                            showCancelButton: false,
                        });

                    }

                }).catch(error => {
                    if(error.toString().includes('409'))
                    {
                        this.$swal.fire({
                            title: 'Tu cuenta ya está activada!',
                            text: 'No necesitas activar tu cuenta, inicia sesión normalmente o recupera tu contraseña si no la recuerdas',
                            type: 'info',
                            showCancelButton: false,
                        });

                    }
                    else
                    {
                        this.$swal.fire({
                            title: 'Usuarios no encontrado',
                            text: 'El email facilitado no parece pertenecer a ninguna cuenta registrada. Registrate y empieza a usar Agustinos.TV',
                            type: 'error',
                            showCancelButton: false,
                        });
                    }
                });



            },
            PedirActivacion(){

                console.log('haciendo login ...');
                ApiService.PedirActivacion(this.email).then(response => {
                    console.log(response);
                    if (response.status == 200) {
                        this.$swal.fire({
                            title: 'Petición enviada',
                            text: 'Revisa tu correo y sigue las instrucciones',
                            type: 'success',
                            showCancelButton: false,
                        });

                    }

                }).catch(error => {
                    if(error.toString().includes('409'))
                    {
                        this.$swal.fire({
                            title: 'Tu cuenta ya está activada!',
                            text: 'No necesitas activar tu cuenta, inicia sesión normalmente o recupera tu contraseña si no la recuerdas',
                            type: 'info',
                            showCancelButton: false,
                        });

                    }
                    else
                    {
                        this.$swal.fire({
                            title: 'Usuarios no encontrado',
                            text: 'El email facilitado no parece pertenecer a ninguna cuenta registrada. Registrate y empieza a usar Agustinos.TV',
                            type: 'error',
                            showCancelButton: false,
                        });
                    }
                });


            },


        }
    }


</script>

<style>
    @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

    .flyout {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        justify-content: space-between;
    }
    .active {
        background-color: rgba(255, 255, 255, 0.1);
    }
    .demo-section {
        padding: 20px 0;
    }
    .demo-section > section {
        border: 1px solid #e0e0e0;
        padding: 15px;
    }
    .demo-section > h4 {
        font-weight: bold;
        margin-bottom: 20px;
    }
    .demo-title {
        color: #9e9e9e;
        font-weight: 700;
        margin-bottom: 0;
        padding-left: 15px;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition-duration: 0.3s;
        transition-property: opacity;
        transition-timing-function: ease-out;
    }

    .fade-enter,
    .fade-leave-active {
        opacity: 0;
    }

    .view{
        background: url("https://mdbootstrap.com/img/Photos/Others/img (42).jpg") no-repeat center center;
        background-size: cover;
        height: 100vh;
    }
</style>
